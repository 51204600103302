/**
 * This file has the code for authorizing the user, to access the specified routes
 */
import { useContext } from "react";
import {  Navigate } from "react-router-dom";
import { AuthContext  } from "../services/authentication-context.js";
const ProtectedRoute = ({ element }) => {
  const { isLoggedIn, loading } = useContext(AuthContext);
    if (loading) {
        return <div>Loading...</div>; 
    }
    // console.log("Is logged in: ", isLoggedIn);
    // When the user is authenticated go to specified component or element else navigate to log in page
  return ( isLoggedIn ? element : <Navigate to="/log-in" /> );
};
export default ProtectedRoute;
