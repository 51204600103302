/**
 * This file has the strcture for mega settings page
 */
import { useState } from "react";
import useStore from "../../services/use-store-data-api";
import ConstantValues from "../../utils/constant-values";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

const MegaSettings = () => {
  // data to be passed to api based on sections
  const [dataToSend, setDataToSend] = useState(null);
  // console.log(ConstantValues);
  const [url, setUrl] = useState(null);
  // client name
  const [cname, setCname] = useState("");
  // Fetching triggerSubmit function from use-store-data-api for triggering submit
  const { triggerSubmit } = useStore(url, dataToSend);
  // Client details
  const [clientDetails, setClientDetails] = useState({
    client: "",
    spoc: "",
    websiteURL: "",
    frequency: [],
  });
  // AMC form dynamic field details
  const [amcDetails, setAMCDetails] = useState({
    reviewerName: "",
    licensedPluginsList: [],
    idealPluginsList: [],
  });
  // handling multiple input field onchange
  const [multipleInputValues, setMultipleInputValues] = useState({
    frequency: "",
    idealPlugins: "",
    licensedPlugins: "",
  });
  // Setting multiple input field values
  const [multipleValues, setMultipleValues] = useState({
    frequency: [],
    idealPlugins: [],
    licensedPlugins: [],
  });
  // Greetings module - clients whom greetings to be sent
  const [greetingClients, setGreetingClients] = useState({
    clientName: "",
    associatedMails: "",
  });
  //
  // ideal plugin category list
  const idealPluginsCategory = ConstantValues.IDEAL_PLUGINS_CATEGORIES;
  // Handling key up
  const handleKeyUp = (e, str) => {
    // console.log(e.keyCode);
    if (e.keyCode === 13) {
      // storing multiple values inside val for manipulating the state variable
      let val = { ...multipleValues };
      if (str === ConstantValues.IDEAL_PLUGIN_STR) {
        // inserting into the array
        val.idealPlugins.push(e.target.value);
        setMultipleInputValues({ ...multipleInputValues, idealPlugins: "" });
      } else if (str === ConstantValues.LICENSED_PLUGIN_STR) {
        val.licensedPlugins.push(e.target.value);
        setMultipleInputValues({ ...multipleInputValues, licensedPlugins: "" });
      } else if (str === ConstantValues.FREQUENCY_STR) {
        val.frequency.push(e.target.value);
        setMultipleInputValues({ ...multipleInputValues, frequency: "" });
      }
      console.log(val);
      setMultipleValues(val);
    }
  };
  // Deleting chips for multiple input values
  const handleDelete = (str, index) => {
    let arr = { ...multipleValues };
    if (str === ConstantValues.IDEAL_PLUGIN_STR) {
      arr.idealPlugins.splice(index, 1);
    } else if (str === ConstantValues.LICENSED_PLUGIN_STR) {
      arr.licensedPlugins.splice(index, 1);
    } else if (str === ConstantValues.FREQUENCY_STR) {
      arr.frequency.splice(index, 1);
    }
    setMultipleValues(arr);
  };
  // Submitting client data
  const clienDataSubmit = async (e) => {
    console.log(clientDetails);
    e.preventDefault();
    try {
      setDataToSend(clientDetails);
      setUrl(ConstantValues.SUBMIT_CLIENT_DETAILS_URL);
      // Triggering useEffect in useStore hook
      triggerSubmit();
    } catch (err) {
      console.log(err);
    } finally {
      setClientDetails({
        client: "",
        spoc: "",
        websiteURL: "",
        frequency: [],
      });
    }
  };
  console.log(amcDetails);
  const amcDataSubmit = () => {
    // Destructuring amc details
    let arr = { ...amcDetails };
    arr.licensedPluginsList = multipleValues.licensedPlugins;
    arr.idealPluginsList = multipleValues.idealPlugins;
    setAMCDetails(arr);
    // storing amc data
    try {
      setDataToSend(amcDetails);
      setUrl(ConstantValues.SUBMIT_AMC_DETAILS_URL);
      // Triggering useEffect in useStore hook
      triggerSubmit();
    } catch (err) {
      console.log(err);
    }
  };
  // Reset AMC details Fields
  const handleAMCResetFields = () => {
    setAMCDetails({ ...amcDetails, reviewerName: "" });
    setMultipleInputValues({
      ...multipleInputValues,
      idealPlugins: "",
      licensedPlugins: "",
    });
    setMultipleValues({
      ...multipleValues,
      idealPlugins: [],
      licensedPlugins: [],
    });
  };
  // handle client names submit to whom greetings to be sent
  const handleGreetingsClientSubmit = () => {
     handleCommaSeperatedInput( setGreetingClients, greetingClients.associatedMails, "associatedMails" );
    try{
      setDataToSend(greetingClients);
      setUrl(ConstantValues.SUBMIT_GREETING_MODULE_DETAILS);
      // Triggering useEffect in useStore hook
      triggerSubmit();
    }catch(err){
      console.log(err);
    }finally{
      // reset the values after submit
      setGreetingClients({
        clientName: "",
        associatedMails: "",
      });
    }
  };
  // handle comma seperated inputs
  const handleCommaSeperatedInput = (setData, data, key) => {
    // seperate strings and then trim them to remove white spaces
    const arr = data.split(",").map((item) => item.trim());
    // update the setter function
    setData((prev) => {
      let obj = prev;
      obj[key] = arr;
      return obj;
    });
  };
  console.log(greetingClients);
  async function handleAMCInfoFetch(){
    console.log(cname);
    const result = await axios.get(ConstantValues.CLIENT_AMC_DETAILS, { 
      params: {
        cname: cname
      }
    });
    console.log(result);
  }
  return (
    <Container className="megaSettingsContainer">
      {/* Client details */}
      <Box component="form">
        <Typography className="sectionTitle">Client Details</Typography>
        <Grid spacing={2} container className="megaSettingsGridContainer">
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            {/* Client name */}
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="client-name"
              label="Client name"
              name="clientName"
              value={clientDetails.client}
              onChange={(e) => {
                setClientDetails({ ...clientDetails, client: e.target.value });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            {/* Website URL */}
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="website-url"
              label="Website URL"
              name="websiteURL"
              value={clientDetails.websiteURL}
              onChange={(e) => {
                setClientDetails({
                  ...clientDetails,
                  websiteURL: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            {/* Spoc name */}
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="spoc-name"
              label="SPOC name"
              name="spocName"
              value={clientDetails.spoc}
              onChange={(e) => {
                setClientDetails({ ...clientDetails, spoc: e.target.value });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            {/* Frequency - query */}
            {/* <TextField
              fullWidth
              value={multipleInputValues.frequency}
              className="megaSettingsInputField"
              id="frequncy"
              label="Frequency"
              name="frequency"
              onChange={(e) => setMultipleInputValues({...multipleInputValues, frequency: e.target.value})}
              onKeyDown={(e) => handleKeyUp(e, "Frequency")}
            /> */}
            {/* <Box className={"chipBox"}>
              {multipleValues.frequency.map((item, index) => (
                <Chip
                  variant="outlined"
                  key={index}
                  className="enteredItemsChip"
                  onDelete={() => handleDelete("Frequency", index)}
                  label={item}
                />
              ))}
            </Box> */}
          </Grid>
          <Grid item md={12} xs={12} className="megaSettingsGrid">
            <Button
              onClick={clienDataSubmit}
              className="submitButton"
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
          <Grid item className="megaSettingsGrid" md={12} xs={12}>
            <Divider className="megaSettingsDivider" />
          </Grid>
        </Grid>
      </Box>
      {/* AMC details */}
      <Box component="form">
        <Typography className="sectionTitle">AMC Details</Typography>
        <Grid spacing={2} container className="megaSettingsGridContainer">
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="reviewer-name"
              label="Reviewer name"
              name="reviewerName"
              onChange={(e) => {
                setAMCDetails({ ...amcDetails, reviewerName: e.target.value });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            <TextField
              fullWidth
              className="megaSettingsInputField"
              value={multipleInputValues.licensedPlugins}
              id="licensed-plugins-list"
              label="Licensed Plugins List"
              name="licensedPluginsList"
              onChange={(e) =>
                setMultipleInputValues({
                  ...multipleInputValues,
                  licensedPlugins: e.target.value,
                })
              }
              onKeyDown={(e) => handleKeyUp(e, "Licensed Plugins")}
            />
            <Box className={"chipBox"}>
              {multipleValues.licensedPlugins.map((item, index) => (
                <Chip
                  variant="outlined"
                  key={index}
                  className="enteredItemsChip"
                  onDelete={() => handleDelete("Licensed Plugins", index)}
                  label={item}
                />
              ))}
            </Box>
          </Grid>
          <Grid item md={4} xs={12} className="megaSettingsGrid">
            <TextField
              fullWidth
              className="megaSettingsInputField"
              value={multipleInputValues.idealPlugins}
              id="ideal-plugins-list"
              label="Ideal Plugins List"
              name="idealPluginsList"
              onChange={(e) =>
                setMultipleInputValues({
                  ...multipleInputValues,
                  idealPlugins: e.target.value,
                })
              }
              onKeyDown={(e) => handleKeyUp(e, "Ideal Plugins")}
            />
            <Box className={"chipBox"}>
              {multipleValues.idealPlugins.map((item, index) => (
                <Chip
                  variant="outlined"
                  key={index}
                  className="enteredItemsChip"
                  onDelete={() => handleDelete("Ideal Plugins", index)}
                  label={item}
                />
              ))}
            </Box>
            {/* <Select
              id="ideal-plugins-category"
              // value={selectedValues.clientName}
              label="Ideal plugins category"
              // onChange={handleChange}
              name={"idealPluginCategory"}
              className="megaSettingsSelectdd"
            >
              {/* Dropdown for client names */}
            {/* {idealPluginsCategory.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    className="megaSettingsSelectddItem"
                  >
                    {item}
                  </MenuItem>
                ))}
            </Select> */}
          </Grid>
          <Grid item md={6} xs={6} className="megaSettingsGrid">
            <Button
              onClick={amcDataSubmit}
              className="submitButton"
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={6} xs={6} className="megaSettingsGrid">
            <Button onClick={handleAMCResetFields} className="ResetLink">
              Reset Section
            </Button>
          </Grid>
          <Grid item className="megaSettingsGrid" md={12} xs={12}>
            <Divider className="megaSettingsDivider" />
          </Grid>
        </Grid>
      </Box>
      <Box component="form">
        <Typography className="sectionTitle">
          Greetings Module Details
        </Typography>
        <Grid container spacing={2} className="megaSettingsGridContainer">
          <Grid item md={6} xs={12} className="megaSettingsGrid">
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="greetings-client-name"
              label="Client Name"
              name="greetingsClient"
              value={greetingClients.clientName}
              // add into the state variable when the value changes
              onChange={(e) => {
                setGreetingClients({
                  ...greetingClients,
                  clientName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} className="megaSettingsGrid">
            <TextField
              fullWidth
              className="megaSettingsInputField"
              id="greetings-associated-emails"
              label="Associated Emails"
              name="greetingsAssociatedMails"
              value={greetingClients.associatedMails}
              multiline
              // add comma seperated string into the state variable
              onChange={(e) =>
                setGreetingClients({
                  ...greetingClients,
                  associatedMails: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={12} xs={12} className="megaSettingsGrid">
          <Button
              onClick={handleGreetingsClientSubmit}
              className="submitButton"
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
          <Grid item md={12} xs={12} className="megaSettingsGrid">
          <TextField
              fullWidth
              className="megaSettingsInputField"
              value={cname}
              onChange={(e) =>
                setCname(e.target.value)
              }
            />
          </Grid>
          <Grid item md={12} xs={12} className="megaSettingsGrid">
            <Button
              onClick={handleAMCInfoFetch}
              className="submitButton"
              variant="outlined"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default MegaSettings;