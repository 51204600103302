/**
 * This file has all the routing logic for the application
 */
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AMCForm from "./pages/amc-module/amc-form";
import MegaSettings from "./pages/mega-settings/mega-settings";
import SignIn from "./pages/sign-in/sign-in";
import Homepage from "./pages/homepage/homepage.js";
import GreetingsForm from "./pages/greetings-module/upload-greetings-form";
// importing ProtectedRoute component for authorized access to pages
import ProtectedRoute from  "./services/protecting-route-for-authorization";
// importing the authentication provider
import { AuthProvider } from "./services/authentication-context.js";
import "./assets/CSS/ezsupport-desktop.css";
const EZSupport = () => {
  // Creating state variable for authentication
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // function to handle state of isAuthenticated
  const handleLogin = () => {
    setIsAuthenticated(true);
  };
  return (
    // passing the paths which we want protected inside ProtectedRoute component
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path={"/log-in"} element={<SignIn  />} />
        <Route
          path="/settings"
          element={<ProtectedRoute element={<MegaSettings />} />}
        />
        <Route
          path="/amc-form"
          element={<ProtectedRoute element={<AMCForm />} />}
        />
        <Route
          path="/"
          element={<ProtectedRoute element={<Homepage />} />}
        />
        {/* <Route
          path="/upload-greetings"
          element={<ProtectedRoute element={<GreetingsForm />} />}
        /> */}
        {/* <Route path="/upload-greetings" element={<GreetingsForm />} /> */}
      </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};
export default EZSupport;