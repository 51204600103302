import { useState } from 'react';
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
const InputFieldList = ({ setOpenModal, n, title, submitData }) =>{
	//show modal
	const [show, setShow] = useState(true);
	let values = [];
	//closing modal
	const handleClose = () => {
		submitData(values);
		setShow(false);
		setOpenModal(null);
	}
	// dynamically creating input fields
	const InputField = () =>{
		const arr = [];
		for(let i=0;i<n;i++){
			arr.push(
			<TextField
				key={i}
				margin="normal"
				fullWidth
				id="modal-input-field"
				label={`Enter ${title}`}
				name="inputField"
				onChange={(e)=>{values[i]=e.target.value}}
				/>)
		}
		return arr;
	}
	
	return(
	<Modal
        open={show}
        onClose={handleClose}
		className="inputFieldListModal"
      >
        { n === 0 ? 
		(	<Box className="inputFieldListBox">
				<Typography>Please enter a valid input</Typography>
			</Box>
		):(
			<Box className="inputFieldListBox">
          <Typography id="input-field-modal-title" variant="h6" component="h2">
		  		Add {title}
          </Typography>
          {InputField()}
		  <Button onClick={()=>{handleClose() }} variant="outlined">Add</Button>
        </Box>
		)}
      </Modal>
	);
}
export default InputFieldList;
