/**
 * This file has the structure for toggle button
 */
import Switch from '@mui/material/Switch';
const ToggleButton = ({isChecked, isDisabled, setData, data, value, valueKey}) =>{
    // When the toggle button is checked store the values in valuesArray
    const handleClick = (e) => {
        // Perform following code when setter is passed
        if(setData){
            // if the toggle is checked
            if(e.target.checked){
                // push into the subarray, accessed using given key
                setData((prevObj) => {
                    let obj = prevObj;
                    obj[valueKey].push(value);
                    // return the array
                    return obj;
                });
            }else{
                // Removing the value when toggle is unchecked
                setData((prevObj) => {
                    let obj = prevObj;
                    let arr = obj[valueKey].filter((item) => item !== value);
                    obj[valueKey] = arr;
                    return obj;
                });
            }
        }
    }
    return(
        <Switch onClick={handleClick} checked={isChecked} disabled={isDisabled} className="ezsToggleButton"  />
    )
}
export default ToggleButton;