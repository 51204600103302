/**
 * This file has the code for homepage
 */
// routing
import { Link as RoutingLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
const Homepage = () =>{
    return(
        <Stack spacing={2} direction="row">
        <RoutingLink to="/amc-form">
        <Button variant="outlined">AMC Form</Button>
        </RoutingLink>
        <RoutingLink to="/settings">
        <Button variant="outlined">Mega Settings</Button>
        </RoutingLink>
      </Stack>
    );
}
export default Homepage;