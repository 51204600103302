/**
 * This file contains the strcture for AMC form
 */
import React, { useState } from "react";
// for routing
import { useNavigate } from 'react-router-dom';
// import { useAuth } from "../../services/authentication-context.js";
import rankMathSEOModuleImage from "../../assets/media/amc-module/rankmath-seo-modules.png";
import InputFieldList from "../../components/input-field-list";
import ResponsiveTable from "../../components/responsive-table";
// Storing form data
import useStore from "../../services/use-store-data-api";
// Fetching required data
import useFetch from "../../services/use-fetch-data-api";
// importing constant values
import ConstantValues from "../../utils/constant-values";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
// Importing css for form
import "../../assets/CSS/amc-mdoule/amc-form.css";
// to be fetched from backend
const memberNames = ["Sakshi", "Manisha", "Ganesh"];
// AMC cycle list
// to be fetched from backend
const amcCycleList = ["Monthly", "Bi-monthly", "Quatrly"];
// to be fetched from backend
const licensedPluginsList = ["Imagify", "RankMath SEO", "WP Rocket", "Better Search and Replace", "User Role Editor Pro", "WP All Import", "WP All Export", "Visual Composer"];
const AMCForm = () => {
  // const { logout } = useAuth(); // Get logout function from context
  //Handling date
  const currentDate = new Date();
  // for refreshing the page
  const navigate = useNavigate();
  // Storing form data in this state variable
  const [selectedValues, setSelectedValues] = useState({
    clientName: "",
    spocName: "",
    memberName: "",
    amcCycle: "",
    websiteURL: "",
    activatedLicensedPlugins: [],
    numberOfActivePlugins: 0,
    updatedPluginsList: [],
    autoUpdatesEnabledPluginList: [],
    isDiscourageSearchEngineOptionEnabled: false,
    isTimezoneSetToKolkata: false,
    isPermalinkFormatSetToPostname: false,
    parentTheme: "",
    anyAdditionalTheme: false,
    parentThemeHasUpdates: false,
    requiredRankMathSEOModulesEnabled: false,
    isWordpressUpdated: false,
    sitemap: null,
    isCerberLogChecked: false,
    completedOn: "",
    domainAuthorityScore: 0,
    areMalcureDefinitionsUpdated: false,
    isBulkOptimizationHundredPercent: false,
    postSMTPIssues: "No Issues",
    sslCertificateExpiryDate: null,
    sslCertificateIssuedBy: null,
  });
  // fetch data from mega settings page
  let { fetchedData, loading, error } = useFetch(
    ConstantValues.FETCH_MEGA_SETTINGS_DETAILS_URL,
    null
  );
  // triggering useStore hook
  let { triggerSubmit } = useStore(ConstantValues.SUBMIT_AMC_FORM_DATA, selectedValues);
  if (loading) {
    // console.log("Loading....");
  } else {
    // console.log("Loading stopped");
  }
  if (error) {
    console.log(error);
  }
  // fetching plugins data
  const {
    fetchedData: fetchPlugins,
    loading: loadingPlugin,
    error: errorPlugin,
  } = useFetch(
    ConstantValues.FECTH_PLUGINS_LIST_URL,
    null
  );
  // postSMTP issues
  const submitedPostSMTPIssues = (data) => {
    console.log(data.length);
    setSelectedValues({ ...selectedValues, postSMTPIssues: data[0]});
  };
  // opening respective modal
  const [openModal, setOpenModal] = useState(null);
  // Client details
  const clientDetails = fetchedData && fetchedData[1];
  // Function to set spoc name and website url dynamically
  const dynamicAssignment = (key) => {
    // execute only when client details are present
    if (clientDetails) {
      // Storing clients array
      const arrayOfClientDetails = clientDetails.clients;
      // Looping through the array to get the document which has given clients details
      for (let i = 0; i < arrayOfClientDetails.length; i++) {
        // when we get the document
        if (arrayOfClientDetails[i].client === key) {
          // Set the spoc name and website URL for the client
          setSelectedValues({
            ...selectedValues,
            clientName: key,
            spocName: arrayOfClientDetails[i].spoc,
            websiteURL: arrayOfClientDetails[i].websiteURL,
          });
          // break through the loop
          break;
        }
      }
    }
  };
  // Handling client name, member name and amc cycle change
  const handleChange = (event) => {
    const key = event.target.name;
    let tempObj = selectedValues;
    tempObj[key] = event.target.value;
    // Client name
    if (key === "clientName") {
      dynamicAssignment(event.target.value);
    } //Member name
    else if (key === "memberName") {
      setSelectedValues({
        ...tempObj,
        memberName: event.target.value,
      });
    } //amc cycle
    else {
      setSelectedValues({
        ...tempObj,
        amcCycle: event.target.value,
      });
    }
  };
  const [fetchResponsiveTableData, setFetchResponsiveTableData] =
    useState(false);
  // Handling form submit
  const handleAMCFormSubmit = () => {
    // handle setting the date at the tiome of submission
    let date = currentDate.toLocaleString();
    // triggering responsive table data setter
    setFetchResponsiveTableData(true);
    // add active plugins length only when it is rendered
    fetchPlugins.ActivePlugins &&
      setSelectedValues({
        ...selectedValues,
        numberOfActivePlugins: fetchPlugins.ActivePlugins.length, completedOn : date
      });
      handleFormDataStoring();
  };
  // console.log("Selected Values: ", selectedValues);
  const tableHeads = ConstantValues.PLUGIN_DETAILS_TABLE_HEADS;
  let data = [];
  // storing true or false values serially as per the installed plugins list that whether this plugin in activated or not
  if (fetchPlugins) {
    if (fetchPlugins.AllPlugins && fetchPlugins.ActivePlugins) {
      // Storing boolean values for active plugins in an array
      const isPluginActivated = fetchPlugins.AllPlugins.map((plugin) =>
        fetchPlugins.ActivePlugins.includes(plugin)
      );
      // Storing boolean values for licensed plugins which are installed
      const isLicensedPluginPresent = fetchPlugins.AllPlugins.map((plugin) => licensedPluginsList.includes(plugin)) ;
      // 
      fetchPlugins.AllPlugins.map((plugin, index) => {
        data.push({
          col1: plugin,
          col2: isPluginActivated[index],
          col3: isLicensedPluginPresent[index],
        });

      });
    }
  }
  // store the data using useStore hook
  const handleFormDataStoring = () =>{
    try{
      triggerSubmit();
    }catch(err){
      console.log(err);
    }finally{
      // setTimeout(() => {
      //   // Redirect to the same page for page refresh
      //   navigate('/amc-form');
      // }, 3000);
    }
  }
  // storing responsive table data
  return (
    <Container className="amcFormContainer">
      <Grid container spacing={2} className="amcFormGridContainer">
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant={"h3"} className="amcFormTitle">
            AMC Form
          </Typography>
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant={"h5"} className="amcFormSectionTitle">
            Client details
          </Typography>
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <FormControl className="amcFormFormControl" fullWidth>
            <InputLabel className="amcFormInputLabel">Client name</InputLabel>
            <Select
              id="amc-client-name"
              value={selectedValues.clientName}
              label="Client name"
              onChange={handleChange}
              name={"clientName"}
              className="amcFormSelectdd"
            >
              {/* Dropdown for client names */}
              {clientDetails &&
                clientDetails.clients.map((clientDoc, index) => (
                  <MenuItem
                    key={index}
                    value={clientDoc.client}
                    className="amcFormSelectddItem"
                  >
                    {clientDoc.client}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          {/* Auto fill field */}
          <TextField
            InputProps={{
              readOnly: true,
            }}
            value={
              selectedValues.clientName === ""
                ? "Website URL"
                : selectedValues.websiteURL
            }
            fullWidth
            className="amcFormInputField"
          />
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          {/* Auto fill field */}
          <TextField
            InputProps={{
              readOnly: true,
            }}
            value={
              selectedValues.clientName === ""
                ? "SPOC name"
                : selectedValues.spocName
            }
            fullWidth
            className="amcFormInputField"
          />
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <FormControl className="amcFormFormControl" fullWidth>
            <InputLabel className="amcFormInputLabel">Member name</InputLabel>
            {/* Dropdown for member names */}
            <Select
              id="amc-member-name"
              value={selectedValues.memberName}
              label="Member name"
              onChange={handleChange}
              name={"memberName"}
              className="amcFormSelectdd"
            >
              {memberNames.map((names, index) => (
                <MenuItem
                  key={index}
                  value={names}
                  className="amcFormSelectddItem"
                >
                  {names}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <FormControl className="amcFormFormControl" fullWidth>
            <InputLabel className="amcFormInputLabel">AMC cycle</InputLabel>
            {/* Dropdown for AMC cycles */}
            <Select
              id="amc-cycle"
              value={selectedValues.amcCycle}
              label="AMC cycle"
              onChange={handleChange}
              name={"amcCycle"}
              className="amcFormSelectdd"
            >
              {amcCycleList.map((cycle, index) => (
                <MenuItem
                  key={index}
                  value={cycle}
                  className="amcFormSelectddItem"
                >
                  {cycle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={4}></Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}></Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant={"h5"} className="amcFormSectionTitle">
            Plugin details
          </Typography>
        </Grid>
        {/* Plugins info table */}
        <Grid item md={12} xs={12} className="amcFormGrid">
          <ResponsiveTable
            data={data}
            tableHeads={tableHeads}
            numberOfColumns={tableHeads.length}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
            triggerSubmit={fetchResponsiveTableData}
            handleTrigger={setFetchResponsiveTableData}
          />
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <Typography className="amcFormSectionText">
            Number of active plugins
          </Typography>
          <TextField
            type="number"
            fullWidth
            className="amcFormInputField"
            // onChange={(e) => {
            //   setSelectedValues({
            //     ...selectedValues,
            //     numberOfActivePlugins: e.target.value,
            //   });
            // }}
            value={
              fetchPlugins.ActivePlugins && fetchPlugins.ActivePlugins.length
            }
          />
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}></Grid>
        {/* Settings information */}
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant="h5" className="amcFormSectionTitle">
            Settings Information
          </Typography>
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Stack
            direction={{ md: "row", xs: "column" }}
            className="horizontalLabelAndRadioButtonStack"
          >
            <FormLabel className="amcFormLabel">
              Is discourage search engine option checked in Settings {">"}{" "}
              Reading
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-reading"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isDiscourageSearchEngineOptionEnabled: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isDiscourageSearchEngineOptionEnabled: false,
                  });
                }}
              />
            </RadioGroup>
          </Stack>
          <Stack
            direction={{ md: "row", xs: "column" }}
            className="horizontalLabelAndRadioButtonStack"
          >
            <FormLabel className="amcFormLabel">
              Is the timezone set to Asia/Kolkata (+5:30) and date format is "d
              M Y" in Settings {">"} General
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-general"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isTimezoneSetToKolkata: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isTimezoneSetToKolkata: false,
                  });
                }}
              />
            </RadioGroup>
          </Stack>
          <Stack
            direction={{ md: "row", xs: "column" }}
            className="horizontalLabelAndRadioButtonStack"
          >
            <FormLabel className="amcFormLabel">
              Is permalinks URL format set to postname in Settings {">"}{" "}
              Permalinks
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-permalink"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isPermalinkFormatSetToPostname: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isPermalinkFormatSetToPostname: false,
                  });
                }}
              />
            </RadioGroup>
          </Stack>
        </Grid>
        {/* Theme information */}
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant="h5" className="amcFormSectionTitle">
            Theme Information
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <Typography className="amcFormSectionText">
            Enter Parent theme name
          </Typography>
          <TextField
            fullWidth
            className="amcFormInputField"
            label="Parent theme"
            onChange={(e) => {
              setSelectedValues({
                ...selectedValues,
                parentTheme: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Are additional themes deleted (except current year theme)?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-reading"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    anyAdditionalTheme: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    anyAdditionalTheme: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={4} xs={12}>
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Does parent theme have update?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-general"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    parentThemeHasUpdates: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    parentThemeHasUpdates: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}></Grid>
        {/* RankMath SEO settings */}
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant="h5" className="amcFormSectionTitle">
            RankMath SEO Information
          </Typography>
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <img src={rankMathSEOModuleImage} className="amcRankMathSEOImage" />
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              As shown in above image, are all rankmath modules enabled?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-settings-general"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    requiredRankMathSEOModulesEnabled: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    requiredRankMathSEOModulesEnabled: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}>
          {/* Add sitemap.xml link */}
          <Typography className="amcRankMathSEOSitemapText">
            Upload sitemap.xml screenshot
          </Typography>
          <TextField
            fullWidth
            className="amcFormInputField"
            id="sitemap-xml-link"
            label="Enter sitemap.xml link"
            name="sitemXmlLink"
            onChange={(e) => {
              setSelectedValues({
                ...selectedValues,
                sitemap: e.target.value,
              });
            }}
          />
        </Grid>
        {/* Security and Optimization Information */}
        <Grid item className="amcFormGrid" md={12} xs={12}>
          <Typography variant="h5" className="amcFormSectionTitle">
            Security and Optimization Information
          </Typography>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Is wordpress updated?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-wordpress-updated"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isWordpressUpdated: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isWordpressUpdated: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Are repeated offenders blacklisted on Cerber activity log?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-cerber"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isCerberLogChecked: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isCerberLogChecked: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Are security definations updated in Malcure tab?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-malcure"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    areMalcureDefinitionsUpdated: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    areMalcureDefinitionsUpdated: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Is Imagify Bulk Optimization completed to 100%?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-imagify"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                control={<Radio className="formRadioButton" />}
                label="YES"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isBulkOptimizationHundredPercent: true,
                  });
                }}
              />
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
                onClick={() => {
                  setSelectedValues({
                    ...selectedValues,
                    isBulkOptimizationHundredPercent: false,
                  });
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={4} className="amcFormGrid" xs={12}>
          <FormControl className="amcFormFormControl">
            <FormLabel className="amcFormLabel">
              Is mail sending blocked on PostSMTP?
            </FormLabel>
            <RadioGroup
              className="amcFormRadioGroup"
              row
              name="radio-buttons-imagify"
            >
              <FormControlLabel
                className="amcFormRadioButton"
                value="yes"
                onClick={() => setOpenModal("add PostSMTP issue")}
                control={<Radio className="formRadioButton" />}
                label="YES"
              />
              {openModal === "add PostSMTP issue" && (
                <InputFieldList
                  setOpenModal={setOpenModal}
                  n={1}
                  title={"PostSMTP Issue"}
                  submitData={submitedPostSMTPIssues}
                />
              )}
              <FormControlLabel
                className="amcFormRadioButton"
                value="no"
                control={<Radio className="formRadioButton" />}
                label="NO"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <Typography className="amcFormSectionText">
            Enter Domain Authority Score
          </Typography>
          <TextField
            fullWidth
            className="amcFormInputField"
            type="number"
            label="Domain authority score"
            onChange={(e) => {
              setSelectedValues({
                ...selectedValues,
                domainAuthorityScore: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <Typography className="amcFormSectionText">
            Enter SSL certificate expiry date
          </Typography>
          <TextField
            fullWidth
            className="amcFormInputField"
            onChange={(e) => {
              setSelectedValues({
                ...selectedValues,
                sslCertificateExpiryDate: e.target.value,
              });
            }}
            type="date"
          />
        </Grid>
        <Grid item md={4} xs={12} className="amcFormGrid">
          <Typography className="amcFormSectionText">
            Enter organization which issued SSL certificate
          </Typography>
          <TextField
            fullWidth
            className="amcFormInputField"
            label="Issued by"
            onChange={(e) => {
              setSelectedValues({
                ...selectedValues,
                sslCertificateIssuedBy: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item className="amcFormGrid" md={12} xs={12}></Grid>
        <Grid item md={12} xs={12} className="amcFormGrid">
          <Button
            variant="outlined"
            onClick={handleAMCFormSubmit}
            className="amcFormSubmitButton"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default AMCForm;