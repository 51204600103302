/**
 * This file has the responsive table which will have n column layout for desktop and desciptive layout for mobile
 */
import React, { useState, useEffect } from "react";
import ToggleButton from "./toggle-button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
const ResponsiveTable = ({data,tableHeads, numberOfColumns, setSelectedValues, selectedValues, triggerSubmit, handleTrigger}) =>{
    // media query to get whether the screen width in below 600px or not
  const isMobile = useMediaQuery('(max-width:600px)');
  // Storing responsive table data
  const [ pluginsData, setPluginsData ] = useState({
    activatedLicensedPlugins:[],
    updatedPluginsList: [],
    autoUpdatesEnabledPluginsList: []
  });
  // Use useEffect to handle state updates when triggerSubmit changes
  useEffect(() => {
    if (triggerSubmit) {
      setSelectedValues({
        ...selectedValues,
        activatedLicensedPlugins : pluginsData.activatedLicensedPlugins,
        updatedPluginsList: pluginsData.updatedPluginsList,
        autoUpdatesEnabledPluginList: pluginsData.autoUpdatesEnabledPluginsList
      });
      handleTrigger(false); // Reset the trigger
    }
  }, [triggerSubmit]);
  // console.log("Plugins data: ",pluginsData);
  return(
    <TableContainer component={!isMobile && Paper} className="responsiveTableContainer">
      {isMobile ? (
        <Grid container spacing={2} className="responsiveTableGridContainer">
          {data.map((row, index) => (
            <React.Fragment key={index}>
            <Grid item xs={8}  className="responsiveTableGridItems responsiveTableGridStart">
                <Typography className="responsiveTableGridItemText">{tableHeads[0]}: </Typography>
                </Grid>
                <Grid item xs={4}  className="responsiveTableGridItems responsiveTableGridStart">
                <Typography className="responsiveTableGridItemText">{row.col1}</Typography>
                </Grid>
                <Grid item xs={8}  className="responsiveTableGridItems">
                <Typography className="responsiveTableGridItemText">{tableHeads[1]}: </Typography>
                </Grid>
                <Grid item xs={4} className="responsiveTableGridItems">
                <ToggleButton isChecked={row.col2} isDisabled  />
                </Grid>
                <Grid item xs={8} className="responsiveTableGridItems">
                <Typography className="responsiveTableGridItemText">{tableHeads[2]}: </Typography>
                </Grid>
                <Grid item xs={4} className="responsiveTableGridItems">
                <ToggleButton setData={setPluginsData} valueKey={"activatedLicensedPlugins"} value={row.col1} />
                </Grid>
                <Grid item xs={8} className="responsiveTableGridItems">
                <Typography className="responsiveTableGridItemText">{tableHeads[3]}: </Typography>
                </Grid>
                <Grid item xs={4} className="responsiveTableGridItems">
                <ToggleButton setData={setPluginsData} valueKey={"updatedPluginsList"} value={row.col1} />
                </Grid>
                <Grid item xs={8} className="responsiveTableGridItems">
                <Typography className="responsiveTableGridItemText">{tableHeads[4]}: </Typography>
                </Grid>
                <Grid item xs={4} className="responsiveTableGridItems">
                <ToggleButton setData={setPluginsData} valueKey={"autoUpdatesEnabledPluginsList"} value={row.col1} />
                </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <Table className="responsiveTable">
          <TableHead className="responsiveTableHead">
            <TableRow className="responsiveTableRow">
              {tableHeads.map((head, index)=>(
                <TableCell className="responsiveTableCell" key={head+index}>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="responsiveTableBody">
            {data.map((row) => (
              <TableRow key={row.col1} className="responsiveTableRow">
                <TableCell className="responsiveTableCell">{row.col1}</TableCell>
                <TableCell className="responsiveTableCell"><ToggleButton isChecked={row.col2} isDisabled  /></TableCell>
                <TableCell className="responsiveTableCell">{row.col3 && (<ToggleButton setData={setPluginsData} valueKey={"activatedLicensedPlugins"} value={row.col1} />)}</TableCell>
                <TableCell className="responsiveTableCell"><ToggleButton setData={setPluginsData} data={pluginsData} valueKey={"updatedPluginsList"} value={row.col1} /></TableCell>
                <TableCell className="responsiveTableCell"><ToggleButton setData={setPluginsData} data={pluginsData} valueKey={"autoUpdatesEnabledPluginsList"} value={row.col1} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
export default ResponsiveTable;