/**
 * This file contains the custom hook which will be used to fetach data from the collections as per the api call
 */
import { useState, useEffect } from "react";
import axios from "axios";
const useFetch = (url, data) =>{
    // Storing fetched data
    const [ fetchedData, setFetchedData ] = useState([]);
    // handling loader and errors
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    useEffect(()=>{
        async function fetchData(){
            await axios.get(url).then(response => {setFetchedData(response.data)});
        }
        // fetch logic inside try block
        try{
            fetchData();
        }catch(err){
            // if any error occurs then set the error
            setError(err);
        }finally{
            // Finally will always gets executed
            setLoading(false);
        }
    },[ data ]);
    // returning fetched data
    return { fetchedData, loading, error };
}
export default useFetch;