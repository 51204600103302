/**
 * This file manages authentication state, persists it using sessionStorage, and provides login/logout functions
 */
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import ConstantValues from "../utils/constant-values";

// Create a Context object for authentication
export const AuthContext = createContext();

// Authentication Provider component that wraps the app
export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [message, setMessage] = useState(''); // State to display user messages

    // Check login status when the app mounts
    useEffect(() => {
        axios.get(ConstantValues.PROTECTED_URL, { withCredentials: true })
            .then((response) => {
                setIsLoggedIn(true); // Set login status to true if authentication is successful
                setMessage(response.data.message);
            })
            .catch(() => {
                setIsLoggedIn(false); // Set login status to false if authentication fails
                setMessage('You are not logged in');
            })
            .finally(() => {
                setLoading(false); // Stop loading indicator after the check completes
            });
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, loading, message }}>
            {children}
        </AuthContext.Provider>
    );
};