/**
 * This file has the constant values used in all the pages
 */
const ConstantValues = {
    // key up code when event id triggered
    KEY_UP_CODE : 13,
    // all the ideal plugins category
    IDEAL_PLUGINS_CATEGORIES : ["SEO/SMM Performance", "Security and Compliance", "Monitoring", "Lead Management", "Access Permissions", "Import Export", "Emailing", "Regulary Needed", "For Blog", "Misc - LES Utilities"],
    // below 3 strings will be used to compare entered strings in the function - currently in mega settings page
    IDEAL_PLUGIN_STR : "Ideal Plugins",
    LICENSED_PLUGIN_STR : "Licensed Plugins",
    FREQUENCY_STR : "Frequency",
    // URLs for connecting to backend
    SUBMIT_CLIENT_DETAILS_URL : "http://134.209.145.1:5000/module/mega-setting/add-mega-setting-client-info",
    SUBMIT_AMC_DETAILS_URL : "http://134.209.145.1:5000/module/amc/add-mega-setting-amc-info",
    FETCH_MEGA_SETTINGS_DETAILS_URL : "http://134.209.145.1:5000/module/mega-setting/fetch-mega-settings-info",
    FECTH_PLUGINS_LIST_URL : "https://hybridgsuite.learnedstudio.com/wp-json/plugin/v1/data",
    SUBMIT_UPLOADED_IMAGE : "http://134.209.145.1:5000/module/greetings/send-greeting-by-mail",
    SUBMIT_GREETING_MODULE_DETAILS : "http://134.209.145.1:5000/module/greetings/submit-greeting-data",
    SUBMIT_AMC_FORM_DATA : "http://134.209.145.1:5000/module/amc/add-amc-form-data",
    FETCH_GREETING_MODULE_CLIENTS : "http://134.209.145.1:5000/module/greetings/fetch-clients",
    LOGIN_URL: "http://134.209.145.1:5000/module/authentication/login",
    LOGOUT_URL: "http://134.209.145.1:5000/module/authentication/logout",
    PROTECTED_URL: "http://134.209.145.1:5000/module/authentication/protected",
    CLIENT_AMC_DETAILS: "http://134.209.145.1:5000/module/amc/get-amc-details",
    PLUGIN_DETAILS_TABLE_HEADS : [ "Plugin", "Is activated?", "Is licence key inserted and activated?", "Is updated?", "Are auto updates Enabled?" ],
}

export default ConstantValues;