/**
 * Entery point for EZSupport application
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import EZSupport from "./ez-support";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		{/* Importing routing file */}
		<EZSupport />	
	</React.StrictMode>
);