/**
 * This is the hook for storing data i.e. passing data to be stored to the backend
 */
import { useEffect, useState } from 'react';
import axios from 'axios';
const useStore = (url, data) =>{
    // console.log("Inside store data Component", data);
    const [submit, setSubmit] = useState(false); // State to trigger the effect
    useEffect(()=>{
        async function storeData(){
            console.log("Inside store data useEffect");
            // passing the data to the backend
            await axios.get(url, {
                params: data
            }).then(response => {response.data.acknowledged === true ? alert("Data inserted successfully") : alert("Data insertion unsuccessful");});
        }
        // call the function only when the submit is triggered and data is present
        if(submit && data){
            storeData();
        }
    },[ data, submit ]);
    // set trigger to true only when it is triggered from the caller component
    const triggerSubmit = () => setSubmit(true);
    // returning trigger function for component to trigger this hook
    return { triggerSubmit };
}
export default useStore;